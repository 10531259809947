<template>
	<div class="layout-main">
		<the-header/>
		<v-main>
			<transition name="route" mode="out-in">
				<router-view />
			</transition>
		</v-main>
		<the-footer />
	</div>
</template>

<script>
    import TheHeader from './the-header';
    import TheFooter from './the-footer';
    //import GlobalBanner from "./global-banner";
    //import BasketPopup from "@/views/basket/basket-popup";

    export default {
        name: "layout-main",
        components: {
            //GlobalBanner,
            //BasketPopup,
            TheHeader,
            TheFooter
        },
    }
</script>

<style lang="scss">

</style>